import React, {useEffect, useRef, useState} from 'react'
import './SendInviteModal.scss'
import Modal from 'react-responsive-modal'
import whatsapp from "../../organizer/secondaryPages/AdminEventPage/Modals/icons/whatsapp.svg";
import {EmailShareButton, WhatsappShareButton} from "react-share";
import gmail from "../../organizer/secondaryPages/AdminEventPage/Modals/icons/gmail.svg";
import messenger from "../../organizer/secondaryPages/AdminEventPage/Modals/icons/messenger.svg";
import {ConfirmButton} from "../Helper";
import {getDefaultWeddingSendInviteMessage} from "../../functions";
import {corporateWeddingEvents} from "../../const";
import {Icon, Uploader} from "rsuite";
import {fi, fil} from "date-fns/locale";
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../Loader";

export default function SendInviteModal({showModal, setShowModal, event, selectedInviteId = 0}) {

    let defaultMessage = ''
    const defaultUrl = 'https://ality.ro/inv/' + event.id_eveniment

    const hiddenFileInput = useRef()
    const [message, setMessage] = useState(defaultMessage)
    const [url, setUrl] = useState(defaultUrl)

    const [file, setFile] = useState(null)
    const [sendingInvites, setSendingInvites] = useState(false)

    useEffect(() => {

        let selectedInvite = event.invite

        if (selectedInviteId !== 0) {
            setUrl(url + '/' + selectedInviteId)

            if (event.additional_invites) {
                selectedInvite = event.additional_invites.find((item) => item.id === selectedInviteId)

                if (!selectedInvite) {
                    selectedInvite = event.invite
                }
            }
        } else {
            setUrl(defaultUrl)
        }

        if (selectedInvite && selectedInvite.sendInviteMessage && selectedInvite.sendInviteMessage.length > 0) {
            setMessage(selectedInvite.sendInviteMessage)
        } else {
            setMessage(getDefaultWeddingSendInviteMessage(event))
        }
        // eslint-disable-next-line
    }, [event, selectedInviteId])

    useEffect(() => {
        sendExcelFileInvitations(file)
        // eslint-disable-next-line
    }, [file]);

    const sendExcelFileInvitations = async (file) => {

        if(file && file.type && file.size) {
            setSendingInvites(true)

            let formData = new FormData()
            formData.append("file", file)
            formData.append("message", message)

            let auth = {
                headers: {
                    "Content-Type": `multipart/form-data;`,
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            axios.post(process.env.REACT_APP_DOMAIN + 'responses/sendInvitesFromExcelFile/' + event.id_eveniment, formData, auth)
                .then(response => {
                    toast.success('Invitațiile au fost trimise!')
                    setSendingInvites(false)
                    setFile(null)
                })
                .catch(err => {
                    toast.error("A apărut o eroare. Vă rugăm să verificați fișierul și să reîncercați!")
                    setSendingInvites(false)
                    setFile(null)
                })
        }



    }


    return (<Modal classNames={{
        overlay: 'modal-overlay',
        modal: 'send-invite-modal',
        closeButton: 'modal-close-button',
    }} open={showModal} onClose={() => setShowModal(false)} onOverlayClick={() => setShowModal(false)} center
                   focusTrapped={false}>
        <div className="modal-content">

            <div className={"send-invite-modal-inner"}>
                <div className={""}>
                    <label>
                        Introduceți un mesaj <br/>
                        <textarea
                            className={""}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </label>
                </div>

                {
                    window.alityAppView ?
                        <>
                            <ConfirmButton
                                style={{width: '100%'}}
                                text={"Trimite invitația"}
                                function={() => {
                                    window.ReactNativeWebView.postMessage(JSON.stringify({
                                        action: 'shareMessage',
                                        message: message + ' ' + url,
                                    }))
                                }}
                            />
                        </>
                        :
                        <>
                            <h6 className={"share-title"}>Cum doriți să trimiteți invitația?</h6>
                            <div className={"share-butons-cont"}>

                                <WhatsappShareButton
                                    title={message}
                                    separator=" " url={url}
                                    windowWidth={"1000"}
                                    windowHeight={"600"}>
                                    <div className={"share-item"}>
                                        <div className={"icon"}>
                                            <img id="whatsapp" src={whatsapp} alt=""/>
                                        </div>
                                        <div className={"text"}>
                                            WhatsApp
                                        </div>
                                    </div>
                                </WhatsappShareButton>

                                <EmailShareButton url={message + ' ' + url}>
                                    <div className={"share-item"}>
                                        <div className={"icon"}>

                                            <img id="messenger1" src={gmail} alt=""/>
                                        </div>
                                        <div className={"text"}>
                                            Email
                                        </div>
                                    </div>
                                </EmailShareButton>

                                <div className={"share-item"} onClick={() => {
                                    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url))
                                }}
                                >
                                    <div className={"icon"}>
                                        <img id="messengerx" src={messenger} alt=""/>
                                    </div>
                                    <div className={"text"}>
                                        Facebook
                                    </div>
                                </div>


                                {corporateWeddingEvents.includes(event.eventType) && (event.packages && event.packages.wedding_participants) ?
                                    <>
                                    {!sendingInvites ?
                                        <>
                                        <div className={"share-item"} onClick={() => {
                                            hiddenFileInput.current.click();
                                        }}>
                                            <div className={"icon"}>
                                                <img id="messengerx" src={"/images/icons/excel.svg"} alt=""/>
                                            </div>
                                            <div className={"text"}>
                                                Încarcă lista de email-uri (Excel)
                                            </div>
                                        </div>
                                        <small style={{textAlign:'center'}}>Adaugă câte o adresă de email pe fiecare rând, în coloana A din primul Sheet</small>


                                        <input
                                            ref={hiddenFileInput}
                                            style={{display: 'none'}}
                                            type="file"
                                            onChange={(e) => setFile(e.target.files[0])} />
                                        </>
                                        :<>
                                            <p style={{textAlign:'center', marginTop:'25px'}}>
                                                Trimitem invitațiile. Te rugăm să așteptați ...<br/>
                                                <Loader/>
                                            </p>
                                        </>}
                                    </> : null}
                            </div>
                        </>
                }
            </div>


        </div>
    </Modal>)

}
