import React from 'react'
import './PublicEvent.scss'
import axios from 'axios'

import { toast } from 'react-toastify'
import {Button, Icon} from 'rsuite'

import HeaderWhite from '../../components/HeaderWhite'
import NavbarColor from '../../components/NavbarColor'
import NavBarEvents from '../../components/eventsPage/NavBarEvents'
import PdfViewer from '../../components/PdfViewer/PdfViewer'

import EventData from './Components/EventData'
import Tickets from './Components/Tickets'
import EventNews from './Components/EventNews'
import {BackAndConfirmButtons, getUrlFromImage} from '../../components/Helper'
import Loader from "../../components/Loader";
import LineupTab from "../../secondaryPages/LineupTab/LineupTab";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/react";
import LineupDjTab from "../../secondaryPages/LineupTab/LineupDjTab";
import Invite from "../../secondaryPages/InviteTab/Invite";
import Modal from "react-responsive-modal";
import {isJsonString} from "../../functions";
import GoogleWebLoginButton from "../../components/GoogleLogin/GoogleWebLoginButton";
import {injectIntl} from 'react-intl';
import AuctionTab from "../../secondaryPages/EventPage/AuctionTab/AuctionTab";
import queryString from "querystring";

let eventAd = null

class PublicEvent extends React.Component {

	isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream

	state = {
		event_id: this.props.match.params.id,
		event_data: {},
		activePage: "Eveniment",
		loading: true,

		locations: [],

		sideBarIsOn: false,
		tabOptions: [],
		tabOptionsIcons: [],

		showInviteAskModal: false
	}

	urlParams = queryString.parse(this.props.location.search)

	onAppMessage = async (message) => {

		let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false

		if(data) {
			// eslint-disable-next-line
			switch (data.action) {
				case 'signInWithApple':
					this.appleSignIn(data.firstName, data.lastName, data.email, data.accessToken)
					break
				case 'signInWithFacebook':
					this.facebookSignIn({accessToken: data.accessToken})
					break
				case 'signInWithGoogle':
					this.googleSignIn({tokenId: data.accessToken})
					break
			}
		}
	}

	async componentDidMount() {
		[window, document].forEach(
			el => el.addEventListener('message', this.onAppMessage))

		toast.info(this.props.intl.formatMessage({id: 'public_event.please_log_in'}))

		window.scrollTo(0, 0);

		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
			//document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
		}

		let id = this.props.match.params.id;
		let slug = this.props.match.params.slug ? this.props.match.params.slug : false

		let payload = {
			id_eveniment: id
		}

		if(slug) {
			payload = {
				slug: slug
			}
		}

		try {
			let response = await axios.post(process.env.REACT_APP_DOMAIN + "getEvent", payload)

			if (response.data.activate_auction !== false) {

				if (this.props.match.params.tab && this.props.match.params.tab === 'auction') {
					this.setState({
						activePage: 'Licitatie'
					})
				}
			}

			let locations = []

			for (const [key, value] of Object.entries(response.data.locations_and_dates)) {
				let dateParts = value.date.split("T")
				let dayParts = dateParts[0].split("-")
				let hourParts = dateParts[1].split(":")

				let location = {
					name: key[0].toLocaleUpperCase() + key.substring(1),
					day: dayParts[2] + "-" + dayParts[1] + "-" + dayParts[0],
					hour: hourParts[0] + ":" + hourParts[1],
					location: value.location,
					date: value.date
				}

				if(value.coordinates && value.coordinates.lat && value.coordinates.lng) {
					location.linkWaze = 'https://www.waze.com/ul?ll=' + value.coordinates.lat + '%2C' + value.coordinates.lng + '&navigate=yes&zoom=17'
					location.linkMaps = 'https://maps.google.com?q=' + value.coordinates.lat + "," + value.coordinates.lng
					location.pinx = value.coordinates.lat
					location.piny = value.coordinates.lng
				}

				locations.push(location)
			}

			this.setState({
				locations: locations
			})

			// if (response.data.privat) {
			// 	toast.error("Acest eveniment nu este public")
			// 	this.props.history.push('/events');
			// 	return
			// }

			var newTabOptions = ["Eveniment"]
			var newTabOptionsIcons = ["star"]

			if (response.data.tip_eveniment === 'festival' || response.data.tip_eveniment === 'concert' || response.data.tip_eveniment === 'conferinta') {
				newTabOptions.push("Program")
				newTabOptionsIcons.push("info")
			}

			if (response.data.tip_eveniment === 'festival') {
				newTabOptions.push("Lineup Dj")
				newTabOptionsIcons.push("info")
			}

			if (response.data.tip_eveniment === 'conferinta' && response.data.activate_lineupdj) {
				newTabOptions.push("Speakers")
				newTabOptionsIcons.push("info")
			}

			if (response.data.tickets_needed !== false) {
				newTabOptions.push("Bilet")
				newTabOptionsIcons.push("ticket")
			}

			if (response.data.activate_invite) {
				newTabOptions.push("Invitatie")
				newTabOptionsIcons.push("envelope")
			}

			if (response.data.FaceRecog.length > 0 && response.data.FaceRecog[0].enable_facerecog) {
				newTabOptions.push("Face Recog")
				newTabOptionsIcons.push("video-camera")
			}

			if (response.data.activate_plan_mese !== false) {
				newTabOptions.push("Plan Locatie")
				newTabOptionsIcons.push("braille")
			}

			if (response.data.activate_menu !== false) {
				newTabOptions.push("Meniu")
				newTabOptionsIcons.push("book2")
			}

			if (response.data.activate_auction !== false) {
				newTabOptions.push("Licitatie")
				newTabOptionsIcons.push("book2")
			}

			//newTabOptions.push("Anunturi")
			newTabOptionsIcons.push("bullhorn")

			if (response.data.activate_informatii_utile !== false) {
				newTabOptions.push("Informatii Utile")
				newTabOptionsIcons.push("info")
			}

			this.setState({
				event_data: response.data,
				tabOptions: newTabOptions,
				tabOptionsIcons: newTabOptionsIcons
			})

			//check event ad data
			for (let elem of response.data.ads) {
				//has file and no ad for this location
				if (elem.file !== null && elem.location === "event") {
					let newAdsData = {
						title: elem.title,
						fileType: elem.file.mime.split("/")[0] === "image" ? "image" : "video"
					}

					if (newAdsData.fileType === "image") {
						newAdsData.image = getUrlFromImage(elem.file)
					} else {
						newAdsData.video = elem.file.url
					}

					newAdsData.link = elem.link
					newAdsData.seen = false

					eventAd = newAdsData

					break
				}
			}

			this.setState({
				loading: false
			})
		} catch (err) {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
			this.props.history.push("/events")
		}
	}

	requestPushToken = async () => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}
		if(window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('getPushNotificationToken')
				.then(token => {

					let data = {
						token: token
					}

					axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)
				})
		}

		if(window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'getPushToken',
				data: localStorage.getItem('jwt')
			}))
		}
	}

	loginGoogle = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginGoogle')
				.then(token => {
					if (token === "NULL" || token === "ERROR") {
						if (token === "ERROR") {
							toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
						}
					} else {
						this.googleSignIn({tokenId: token})
					}
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithGoogle'
			}))
		}
	}

	googleSignIn = (response) => {

		if (!response.tokenId) {
			return;
		}

		let tokenId = response.tokenId
		//tokenId = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE4MmU0NTBhMzVhMjA4MWZhYTFkOWFlMWQyZDc1YTBmMjNkOTFkZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE3MDU1NjE0NDAyMTA4NTczOTI3IiwiZW1haWwiOiJ2bGFkLml2YW5jaXUyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiQVFtbllaazQ5czRoZk5VWHV1dW53dyIsIm5hbWUiOiJWbGFkIEl2YW5jaXUiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKeEJpMm9yVktacnlrY1NwRHJnRjh2T3lUM0JVLXg1OTdsV2xBM2g0UT1zOTYtYyIsImdpdmVuX25hbWUiOiJWbGFkIiwiZmFtaWx5X25hbWUiOiJJdmFuY2l1IiwibG9jYWxlIjoicm8iLCJpYXQiOjE2NDQ0MDYzNjgsImV4cCI6MTY0NDQwOTk2OCwianRpIjoiYTA5Mjk3OTBhODliMDliMDVhZDA0MzY3NzM4YjQ0ZDIzOGM1OWU3YSJ9.GfRbRzpfxQVz5OR5T3N_1RvXljePBvrHUwhqAUetJ9_YgLZCulsiKkbuWznKlgtPIVn_6IeF_HE6jxbgpgqDg_0w5KqsKLAp18Tc0ICZqsOhOwCrQYw9USajjc4YmgFMtO_rtytDqvAx67jZFPsnnNarcVUXlni5gmg1mmsRMNw_xnaGc7w9T0wOqTz36og_z2spjxzSNrK3Gp6BE2wy5mt0KvpYFKq8nJJOJLp8FHMLKLhCKUOGiRK8bnHc_RFJ3x8WxmYwBIT9floaS-4YII5mSSN9y2XLYLVKnPa_N2mWinNNe-DsNkby1QJQ8lw2mW5gGoGTuwCFJiSkBCdTMA"

		let obj = {
			tokenId: tokenId
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/google', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', "remember")

					this.requestPushToken()

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.state.event_data) {
						this.props.history.push('/eventPage/' + this.state.event_data.id_eveniment)
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	loginFacebook = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginFacebook')
				.then(token => {
					if (token === "NULL" || token === "ERROR") {
						if (token === "ERROR") {
							toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
						}
					} else {
						this.facebookSignIn({accessToken: token})
					}
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithFacebook'
			}))
		}
	}

	facebookSignIn = (response) => {

		if (!response.accessToken) {
			return;
		}

		let accessToken = response.accessToken

		let obj = {
			accessToken: accessToken
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/facebook', obj)
			.then(async(response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.state.event_data) {
						this.props.history.push('/eventPage/' + this.state.event_data.id_eveniment)
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	appleSignIn = (firstName, lastName, email, accessToken) => {

		let obj = {
			accessToken: accessToken,
			email: email,
			first_name: firstName,
			last_name: lastName
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/apple', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.state.event_data) {
						this.props.history.push('/eventPage/' + this.state.event_data.id_eveniment)
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	loginApple = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginApple')
				.then(data => {
					let response = data.split('|')
					this.appleSignIn(response[2], response['1'], response['3'], response['0'])
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithApple'
			}))
		}
	}

	goToLogin = () => {
		localStorage.setItem('eventId', this.state.event_data.id_eveniment);
		this.props.history.push("/login")
	}

	goToSignup = () => {
		localStorage.setItem('eventId', this.state.event_data.id_eveniment);
		this.props.history.push("/signup")
	}

	changeMenu = (type) => {
		this.setState({
			activePage: type,
			sideBarIsOn: false
		})
	}

	showSideBar = () => {
		var currentState = this.state.sideBarIsOn

		this.setState({
			sideBarIsOn: !currentState
		})
	}

	closeAd = () => {
		eventAd.seen = true
	}

	goToTickets = () => {
		this.setState({
			activePage: "Bilet"
		})
	}

	goToInvites = () => {
		this.setState({
			activePage: "Invitatie"
		})
	}

	render() {
		if (this.state.loading === true) {
			return <Loader/>
		}

		let toRender = null;

		if (this.state.activePage === "Eveniment")
			toRender = <>

				<div style={{ display: "flex", flexDirection: "column", backgroundColor:"#E8E7EE", padding:"1.5rem 1rem", textAlign:'center', alignItems:'center'}}>
					<h5 style={{textAlign:'center'}}>Pentru a vă înscrie la acest eveniment, vă rugăm să vă conectați la contul dumneavoastră!</h5>


					<div style={{display:'block', textAlign:'center'}}>
					<Button style={{ margin: "20px 5px" }} color="green" onClick={this.goToLogin}>Intră în cont</Button> <Button style={{ margin: "20px 5px", backgroundColor:'#05aff2' }} color="green" onClick={this.goToSignup}>Creează cont nou</Button>
					</div>
					<div className={"social-login-container"} style={{maxWidth:'500px', width:'100%'}}>
						{
							window.flutter_inappwebview || window.alityAppView ?

								<div style={{flexDirection:'column',width:'100%'}}>
									<button onClick={this.loginGoogle}
											className={"social-login-button social-google-login"}
											style={{width:'100%'}}>
										<Icon icon="google"/> Login Google
									</button>

									<button onClick={this.loginFacebook}
											className={"social-login-button social-facebook-login"}
											style={{width:'100%', marginTop:'10px'}}
									>
										<Icon icon="facebook"/> Login Facebook
									</button>

									{
										this.isIOS ?
											<button onClick={this.loginApple}
													className={"social-login-button social-apple-login"}
													style={{width: '100%', marginTop: '10px'}}
											>
												<Icon icon="apple"/> Sign in with Apple
											</button>
											: null
									}
								</div>
								:
								<>

									<GoogleWebLoginButton
										callbackFunction={(val) => this.googleSignIn(val)}
									/>

									<FacebookLogin
										appId="243653927956471"
										autoLoad={false}
										fields="name,email"
										//onClick={(item) => this.facebookSignIn(item)}
										callback={(item) => this.facebookSignIn(item)}
										//cookie
										//
										disableMobileRedirect={true}
										//isMobile={true}
										redirectUri={'https://app.ality.ro'}
										render={renderProps => (
											<button
												className={"social-login-button social-facebook-login"}
												style={{marginLeft: '5px'}}
												type={"button"}
												onClick={renderProps.onClick}
												disabled={renderProps.disabled}
											><Icon icon="facebook"/> Facebook</button>
										)}
									/>
								</>
						}
					</div>
				</div>

				<EventData
				event_id={this.state.event_id} event_data={this.state.event_data} locations={this.state.locations}
				showAdModal={eventAd !== null ? !eventAd.seen : false} closeAd={this.closeAd} adData={eventAd} goToTickets={this.goToTickets}
				goToInvites={this.goToInvites}
			/>
		</>


		if (this.state.activePage === "Bilet")
			toRender = <Tickets
				event_id={this.state.event_id} event_data={this.state.event_data} history={this.props.history}
			/>
		if (this.state.activePage === "Invitatie")
			toRender = <Invite
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={null} user_data={this.state.user_data}  history={this.props.history}
			/>

		if (this.state.activePage === "Plan Locatie")
			toRender = this.state.event_data.plan_mese.length === 0
				? <div className="SeatingChart-container">
					<p>Planul de mese nu a fost incarcat</p>
				</div>
				: <PdfViewer pdfUrl={this.state.event_data.plan_mese[0].url} />

		if (this.state.activePage === "Meniu")
			toRender = this.state.event_data.menu.length === 0
				? <div className="MenuPage-container">
					<p>Pdf-ul meniului nu a fost incarcat</p>
				</div>
				: <PdfViewer pdfUrl={this.state.event_data.menu[0].url} />

		if (this.state.activePage === "Licitatie")
			toRender = <AuctionTab
				event_data={this.state.event_data}
				urlParams={this.urlParams}
				history={this.props.history}
				/>

		if (this.state.activePage === "Anunturi")
			toRender = <EventNews
				event_id={this.state.event_id} event_data={this.state.event_data} participantData={this.state.participantData}
			/>

		if (this.state.activePage === "Program")
			toRender = <LineupTab
				event_id={this.state.event_id} event_data={this.state.event_data} history={this.props.history}
			/>

		if (this.state.activePage === "Lineup Dj")
			toRender = <LineupDjTab
				event_id={this.state.event_id} event_data={this.state.event_data} history={this.props.history}
			/>

		if (this.state.activePage === "Face Recog")
			toRender = <div>
				{
					this.state.loading ?
						<div className={"loading-overlay"}>
							<BeatLoader color={"#00B7AD"} loading={true} css={css} size={30} />
							<span>Se încarcă ...</span>
						</div>
						:
						null
				}
				<div style={{ display: "flex", flexDirection: "column" }}>
					<h5 style={{textAlign:'center'}}>Pentru a încărca un selfie vă rugăm să vă autentificați !</h5>

					<Button style={{ margin: "20px auto" }} color="green" onClick={this.goToLogin}>Intră în cont</Button>

					<div className={"social-login-container"}>
						{
							window.flutter_inappwebview || window.alityAppView ?

								<div style={{flexDirection:'column',width:'100%'}}>
									<button onClick={this.loginGoogle}
											className={"social-login-button social-google-login"}
											style={{width:'100%'}}>
										<Icon icon="google"/> Login Google
									</button>

									<button onClick={this.loginFacebook}
											className={"social-login-button social-facebook-login"}
											style={{width:'100%', marginTop:'10px'}}
									>
										<Icon icon="facebook"/> Login Facebook
									</button>

									{
										this.isIOS ?
											<button onClick={this.loginApple}
													className={"social-login-button social-apple-login"}
													style={{width: '100%', marginTop: '10px'}}
											>
												<Icon icon="apple"/> Sign in with Apple
											</button>
											: null
									}
								</div>
								:
								<>

									<GoogleWebLoginButton
										callbackFunction={(val) => this.googleSignIn(val)}
									/>

									<FacebookLogin
										appId="243653927956471"
										autoLoad={false}
										fields="name,email"
										//onClick={(item) => this.facebookSignIn(item)}
										callback={(item) => this.facebookSignIn(item)}
										//cookie
										//
										disableMobileRedirect={true}
										//isMobile={true}
										redirectUri={'https://app.ality.ro'}
										render={renderProps => (
											<button
												className={"social-login-button social-facebook-login"}
												style={{marginLeft: '5px'}}
												type={"button"}
												onClick={renderProps.onClick}
												disabled={renderProps.disabled}
											><Icon icon="facebook"/> Facebook</button>
										)}
									/>
								</>
						}
					</div>
					<Button style={{ margin: "20px auto" }} color="green" onClick={this.goToSignup}>Creează cont</Button>
				</div>
			</div>

		if (this.state.activePage === "Informatii Utile")
			toRender = this.state.event_data.informatii_utile.length === 0
				? <div className="SeatingChart-container">
					<p>Pdf-ul cu informatii nu a fost incarcat</p>
				</div>
				: <PdfViewer pdfUrl={this.state.event_data.informatii_utile[0].url} />

		return (
			<div className="PublicEvent-container">
				<HeaderWhite shadow={false} />

				<div className="PublicEvent-contents">
					<NavBarEvents
						activePage={this.state.activePage}
						changeMenu={this.changeMenu}
						showSideBar={this.showSideBar}
						isRedCircleNeeded={() => false}
						tabOptions={this.state.tabOptions}
						isDotNeeded={false}
					/>

					<div className="PageContent">
						<div>
							{toRender}
						</div>
					</div>
				</div>

				{this.state.sideBarIsOn && <div>
					<div className="side-bar">
						{this.state.tabOptions.map((option, index) => {
							return <div onClick={() => this.changeMenu(option)} className="side-bar-line" key={option}>
								<Icon icon={this.state.tabOptionsIcons[index]} style={{ color: this.state.activePage === option ? "#1DE9B6" : "white" }} />
								<p className={this.state.activePage === option ? "side-bar-item-active" : "side-bar-item"}>{option}</p>
								<div></div>
							</div>
						})}
					</div>
					<div className="side-bar-blur" onClick={this.showSideBar}></div>
				</div>
				}

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal',
						closeButton: 'modal-close-button',
					}}
					open={this.state.showInviteAskModal}
					focusTrapped={false}
					onClose={() => this.setState({showInviteAskModal: false})}
				>
					<br/>
					<h4>Bine ai venit!</h4>
					<br/>
					<h4>Dorești să participi la acest eveniment?</h4>
					<br/>

					<BackAndConfirmButtons
					textBack={'Nu'}
					functionBack={() => this.setState({showInviteAskModal: false})}
					textConfirm={'Înscrie-te acum!'}
					functionConfirm={() => {
						this.setState({showInviteAskModal: false})
						this.goToInvites()
					}}

					/>

				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default injectIntl(PublicEvent);
