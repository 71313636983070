import React from 'react'
import {Icon} from 'rsuite'
import {toast} from 'react-toastify'
import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'

import './MyInvites.scss'
import Loader from "../../../components/Loader";
import QRCode from "qrcode.react";
import moment from "moment-timezone";
import {getEventNextDate, getMobileOperatingSystem} from "../../../functions";
import {BackAndConfirmButtons, ConfirmButton} from "../../../components/Helper";
import ShareComp from "../../../components/ShareComp/ShareComp";
import {injectIntl} from 'react-intl';
import Modal from "react-responsive-modal";

class MyInvites extends React.Component {
    state = {
        personalEvents: ["Active", "Terminate"],
        filterChoice: "Active",
        invites: [],
        loading: true,
        downloadLoading: false,
        deleteTicketId: null,
        showDeleteTicketModal: false
    }

    os = getMobileOperatingSystem()

    componentDidMount() {
        if (localStorage.getItem('jwt') === null) {
            toast.error("Trebuie sa va logati pentru a folosi site-ul")
            this.props.history.push('/login?redirectBackTo=myinvites');
            return
        }

        this.getData()
    }

    getData = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.post(process.env.REACT_APP_DOMAIN + 'users/myInvites', {}, auth)
            .then(response => {

                let newTickets = response.data
                newTickets.forEach((event,j) => {
                    newTickets[j].data_eveniment = getEventNextDate(newTickets[j]).toISOString()
                })
                newTickets = newTickets.filter((item,i) => (item.user_invite_deleted !== true) )


                this.setState({
                    invites: newTickets,
                    loading: false
                })
            })
            .catch(err => {
                if(err && err.response && err.response.status === 401) {
                    localStorage.clear()
                    toast.error("Informațiile de autentificare au expirat, vă rugăm să vă autentificați!")
                    this.props.history.push('/login');
                } else {
                    toast.error('A apărut o eroare! Vă rugăm reîncercați!')
                }
            })
    }

    openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    downloadImage = async (invite) => {

        if(!this.state.downloadLoading) {
            this.setState({downloadLoading:true})
        } else {
            return
        }

        fetch(process.env.REACT_APP_DOMAIN + 'event/getInviteImage/' + invite.id_participant + '/0', {
            method: 'GET',
            headers: {
                'Content-Type': 'image/jpg',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'invitatie-' + invite.id_participant + '.jpg',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                this.setState({downloadLoading:false})
            });
    }

    downloadImageWebview  = async (invite) => {
        fetch(process.env.REACT_APP_DOMAIN + 'event/getInviteImage/' + invite.id_participant + '/1', {
            method: 'GET',
        })
            .then((response) => response.text())
            .then((res) => {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'downloadMedia',
                    url: res,
                }))
                //toast.success('Invitația a fost salvată în galeria telefonului!')
            })
    }

    goBack = () => {
        this.props.history.go(-1)
    }

    getTicketsFiltered = () => {
        if(this.state.filterChoice === 'Active') {
            return this.state.invites.filter((item, i) =>
                (item.event.data_sfarsit_eveniment &&  item.event.data_sfarsit_eveniment.length > 0 ?
                    moment(item.event.data_sfarsit_eveniment).endOf('day') >= moment()
                :
            moment(item.event.data_eveniment) >= moment(new Date()))
            )
        } else {
            return this.state.invites.filter((item, i) =>
                (item.event.data_sfarsit_eveniment &&  item.event.data_sfarsit_eveniment.length > 0 ?
                    moment(item.event.data_sfarsit_eveniment).endOf('day') < moment()
                    :
                    moment(item.event.data_eveniment) < moment(new Date())))
        }
    }

    deleteTicket = async () => {
        this.setState({loading: true,
            deleteTicketId: null,
            showDeleteTicketModal: false
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'tickets/userDeleteInvite', {
            participant_id: this.state.deleteTicketId
        }, auth)

        this.getData()
    }

    render() {
        if (this.state.loading) {
            return <div className="MyInvites-container font-family-poppins-300">
                <HeaderWhite goBack={this.goBack}/>

                <h4 className="MyInvites-TopText">Invitațiile mele</h4>

                <div className="MyInvites-contents">
                    <Loader/>
                </div>

                <NavbarColor history={this.props.history} active={"Profile"}/>
            </div>
        }

        return (
            <div className="MyInvites-container font-family-poppins-300">
                <HeaderWhite goBack={this.goBack}/>

                <h4 className="MyInvites-TopText">Invitațiile mele</h4>

                <div className="MyInvites-contents">

                    <div className="Events-Filter">
                        {this.state.personalEvents.map((type, index) => {
                            return <div key={index} className={this.state.filterChoice === type ? "Events-Status-Choice" : "Events-Status-Option"}
                                        onClick={() => this.setState({filterChoice: type})}>
                                {/*{type === 'Terminate' ? 'Finalizate' : type} */}
                                {this.props.intl.formatMessage({id: 'events.attending_events.types.' + type})}
                            </div>
                        })}
                    </div>

                    {
                        this.state.invites.length === 0 ?
                            <p style={{marginTop:'20px', textAlign:'center'}}>
                                Nu ați primit nicio invitație.
                            </p>
                            :null
                    }

                    {
                        this.getTicketsFiltered().map((invite, i) => (
                            <div key={i} className="invite" style={(invite.bilet_verificat ? {borderColor:'red'} : {borderColor:'green'})}>

                                <div style={{width:'100%', textAlign:'right'}}>
                                    <span style={{padding:'5px', marginRight:'5px', marginTop:'2px', color:'#F26157', cursor:'pointer'}} onClick={() => {
                                        this.setState({
                                            deleteTicketId: invite.id_participant,
                                            showDeleteTicketModal: true
                                        })
                                    }}>
                                    <Icon icon='trash' style={{ fontSize: '17px'}}/></span>
                                </div>

                                <div>{invite.event.title}</div>
                                <div>{moment(invite.event.data_eveniment).format('HH:mm DD/MM/YYYY')}</div>

                               <div>
                                    <QRCode size={180} value={"https://app.ality.ro/checkTicket/000000000000" + invite.id_participant} />
                                </div>

                                <div className="invite-state">Stare invitație:
                                    {invite.bilet_verificat
                                        ? <p style={{ color: "red" }}> SCANATĂ</p>
                                        : <p style={{ color: "green" }}> VALIDĂ</p>
                                    }
                                </div>

                                {
                                    window.alityAppView ?
                                        <div style={{width:'100%'}}>
                                            <ConfirmButton
                                                text={'Salvează în galeria foto'}
                                                function={() => this.downloadImageWebview(invite)}
                                            />
                                        </div>
                                        :
                                        <div style={{width:'100%'}}>
                                            <ConfirmButton
                                                text={'Descarcă invitația'}
                                                function={() => this.downloadImage(invite)}
                                            />
                                        </div>

                                }

                                {
                                    this.os === 'iOS'?
                                        <div onClick={() => this.openLink(process.env.REACT_APP_DOMAIN + 'event/generateAppleWalletInvitePass/' + invite.id_participant)}>
                                            <img src={'/images/icons/apple_wallet.svg'} style={{width:'140px'}} alt={""} />
                                        </div>
                                        :null
                                }

                                {
                                    this.os === 'Android'?
                                        <div onClick={() => this.openLink(process.env.REACT_APP_DOMAIN + 'event/generateGoogleWalletInvitePass/' + invite.id_participant)}>
                                            <img src={'/images/icons/google_wallet.svg'} style={{height:'30px'}} alt={""} />
                                        </div>
                                        :null
                                }

                                <ShareComp
                                    url={process.env.REACT_APP_PAGE + 'downloadInvite/' + invite.id_participant}
                                    message={''}
                                />

                            </div>
                        ))
                    }


                </div>

                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal',
                    closeButton: 'modal-close-button',
                }} center open={this.state.showDeleteTicketModal} onClose={() => this.setState({
                    deleteTicketId: null,
                    showDeleteTicketModal: false
                })} focusTrapped={false}>

                    <br/>
                    <h5>Sigur doriți să ștergeți acestă invitație ?</h5>
                    <br/>
                    <p>Operațiunea este ireversibilă.</p>
                    <br/>

                    <BackAndConfirmButtons
                        textBack={'Nu'}
                        functionBack={() => this.setState({
                            deleteTicketId: null,
                            showDeleteTicketModal: false
                        })}
                        textConfirm={'Da'}
                        functionConfirm={() => this.deleteTicket()}
                    />

                </Modal>

                <NavbarColor history={this.props.history} active={"Profile"}/>
            </div>
        )
    }
}

export default injectIntl(MyInvites);
